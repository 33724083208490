import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Multiselect from '../SelectComponent/SelectComponent'
import './UserForm.css'
import apiBase from '../../utils/API'

const UserForm = ({ handleSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [teams, setTeams] = useState([])

  const [formData, setFormData] = useState({
    personal_name: '',
    team_id: '',
  })

  const handleChange = (e) => {
    setFormData({
      personal_name: e.target.value,
      team_id: selectedOption?.value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    handleSubmit(formData)
    setFormData({
      personal_name: '',
      team_id: '',
    })
  }

  const getTeams = async () => {
    const loginToken = localStorage.getItem('loginToken')

    const url = `${apiBase}api/teams`

    const response = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${loginToken}` },
    })
    const responseData = await response.json()
    setTeams(responseData)
  }

  useEffect(() => {
    getTeams()
  }, [])

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    setFormData({
      personal_name: formData.personal_name,
      team_id: selectedOption?.value,
    })
  }

  return (
    <form className='form-container' onSubmit={onSubmit}>
      <span className='span-title-form'>
        <FontAwesomeIcon icon={faUser} size='2x' className='title-icon-form' />
        <h4>Registrar en personal</h4>
      </span>
      <span className='span-subtitle-form'>
        <h5>Registrar personal y definir el sublíder</h5>
      </span>
      <div className='input-container'>
        <label className='input-label'>Nombre</label>
        <input
          className='input'
          type='text'
          name='personal_name'
          value={formData.personal_name}
          onChange={handleChange}
          placeholder='Nombre'
          required
        />
      </div>
      {/* <div className='input-container'>
        <label className='input-label'>Personal phone number</label>
        <input
          className='input'
          type='number'
          name='personal_name'
          value={formData.personal_name}
          onChange={handleChange}
          placeholder='Phone number'
          required
        />
      </div> */}
      <div className='team-multiselect'>
        <Multiselect
          options={teams}
          selectedOption={selectedOption}
          handleChange={handleSelectChange}
        />
      </div>
      <button className='post-form-button' type='submit'>
        Registrar
      </button>
    </form>
  )
}

export default UserForm
